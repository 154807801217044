import { withStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

export const customTypography = (color) => {
  return withStyles({
    root: {
      color: color,
    },
  })(Typography)
}

export const customButton = (color, hoverColor) => {
  return withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(color),
      backgroundColor: color,
      '&:hover': {
        backgroundColor: hoverColor,
      },
    },
  }))(Button)
}
