import React from 'react'
import { Container } from '@material-ui/core'
import './index.scss'

export default function AppHeadingTitle(props) {
  const { index = 1, shortCode = '', title } = props

  return (
    <h3 className="AppHeadingTitle-title">
      <Container className="AppHeadingTitle-container">
        <span className="AppHeadingTitle-index">
          {shortCode || (index < 10 ? `0${index}` : index)}
        </span>
        <span className="AppHeadingTitle-content">{title}</span>
      </Container>
    </h3>
  )
}
