import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useMediaQuery, CircularProgress } from '@material-ui/core'
import Button from 'components/widgets/Button'
import { downloadAttachmentWebinar } from 'store/Webinar'
import { useReference } from 'store/Reference'
import { downloadFile } from 'utils'
import { checkUrl } from 'utils/common'
import toast from 'utils/toast'
import { FAVORITE_TYPES, IS_USE_DOWNLOAD, TRACKING } from 'config/constants/common'
import { pageEvent } from 'utils/tracker'
import './index.scss'

export default function ButtonActions(props) {
  const { t } = useTranslation('common')
  const { listFavorite, addToMyList, removeFromMyList } = useReference()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingDownload, setLoadingDownload] = useState(false)
  const match400 = useMediaQuery('(max-width: 400px)')
  const {
    type = FAVORITE_TYPES.WEBINAR,
    isDownload,
    filesDownload = [],
    webinarId = null,
    panelId = null,
    hideDownload,
    hideAddRemove,
    hideRemove,
  } = props
  let { trackingEventSource } = props

  trackingEventSource = trackingEventSource
    ? (webinarId && trackingEventSource + '_' + TRACKING.SOURCE.WEBINAR_DETAIL + webinarId) ||
      (panelId && trackingEventSource + '_' + TRACKING.SOURCE.EXHIBITION_DETAIL + panelId)
    : ''

  // check if webinar | exhibition had added or not
  const { isAdded, savedId } = useMemo(() => {
    const itemAdded =
      listFavorite.find((favoriteItem) => {
        const { webinarId: favoriteWebinarId, panelId: favoritePanelId, savedType } = favoriteItem

        return (
          (savedType === FAVORITE_TYPES.WEBINAR && webinarId === favoriteWebinarId) ||
          (savedType === FAVORITE_TYPES.EXHIBITION && panelId === favoritePanelId)
        )
      }) || {}
    const { savedId } = itemAdded

    return {
      isAdded: !isEmpty(itemAdded),
      savedId,
    }
  }, [listFavorite, webinarId, panelId])

  /**
   * Handle add webinar to my list favorite
   */
  async function togglePostToMyFavorite() {
    setLoading(true)
    try {
      const fncExecute = isAdded ? removeFromMyList : addToMyList
      const requestParamsByType = type === FAVORITE_TYPES.WEBINAR ? { webinarId } : { panelId }
      const requestParams = isAdded
        ? { savedIds: [savedId] }
        : { savedType: type, ...requestParamsByType }
      const msg = isAdded ? 'lbl_remove_from_favorite_success' : 'lbl_add_to_favorite_success'
      const msgType = type === FAVORITE_TYPES.WEBINAR ? 'lbl_webinar' : 'lbl_panel'

      await fncExecute(requestParams, () => pageEvent(trackingEventSource, 'add'))
      toast.success(t(`notice:${msg}`, { type: t(`common:${msgType}`) }))
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  /**
   * Download webinar attachments
   *
   * @param {Array} filesDownload Webinar ecmsEventFilesByWebinarId
   */
  function handleDownloadAttachment() {
    setLoadingDownload(true)
    filesDownload.forEach(async (file) => {
      const { fileName, url } = file || {}
      if (!url || !checkUrl(url)) return

      try {
        const res = await downloadAttachmentWebinar(url)
        const { data = null } = res || {}
        if (!data) {
          toast.error('error:lbl_something_went_wrong')
          return
        }

        const fileType = url.split('.').pop()
        pageEvent(trackingEventSource, 'download')
        downloadFile(data, fileName, fileType)
        setLoadingDownload(false)
      } catch (e) {
        toast.error('error:lbl_something_went_wrong')
        setLoadingDownload(false)
        console.log(e)
      }
    })
  }
  const IconAddToList = isAdded ? 'remove' : 'add'

  return (
    <div className="WebinarItem-actions_groupBtn">
      {!hideAddRemove && (!isAdded || (isAdded && !hideRemove)) && (
        <div
          className={`WebinarItem-actions_wrapBtn ${
            isLoading && 'disabled'
          } WebinarItem-actions-${IconAddToList}`}
          onClick={togglePostToMyFavorite}
        >
          <Button
            className={`WebinarItem-actions_btn WebinarItem-actions_${
              isAdded ? 'btnRemove' : 'btnAdd'
            }`}
            disabled={isLoading}
          >
            <span
              className={`WebinarItem-btn_icon Icon-ic Icon-ic_interactive Icon-${IconAddToList}`}
            />
          </Button>
          {!match400 && (
            <span className="WebinarItem-btn_label">{t(`lbl_${IconAddToList}_en`)}</span>
          )}
        </div>
      )}

      {!hideDownload && IS_USE_DOWNLOAD && (
        <div
          className={`WebinarItem-actions_wrapBtn download ${
            !isDownload && 'disabled'
          } WebinarItem-actions-download`}
          onClick={!isDownload ? () => {} : handleDownloadAttachment}
        >
          <Button className="WebinarItem-actions_btn" disabled={!isDownload}>
            {isLoadingDownload ? (
              <CircularProgress className="WebinarItem-btn_loading" size={20} />
            ) : (
              <span
                className={`WebinarItem-btn_icon Icon-ic Icon-ic_interactive Icon-${
                  isDownload ? 'download' : 'download_disable'
                }`}
              />
            )}
          </Button>
          {!match400 && (
            <span
              className={`WebinarItem-btn_label WebinarItem-btn_label${
                isDownload ? '' : '-disable'
              }`}
            >
              {t('lbl_download_en')}
            </span>
          )}
        </div>
      )}
    </div>
  )
}
