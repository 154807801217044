/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { isIOS, isMobileOnly } from 'react-device-detect'
import { Button, Box, Container, useMediaQuery } from '@material-ui/core'
import { PlayCircleOutline } from '@material-ui/icons'
import SEO from 'components/Seo'
import AppHeading from 'components/AppHeading'
import AppHeadingTitle from 'components/AppHeading/Title'
import ModalVideo from 'components/Video/ModalVideo'
import Wavesf from 'components/Audio/audio-wave-surfer'
import ImageSlider from './ImageSlider'
import ImageViewer from 'components/widgets/ImageViewer'
import { useAuth } from 'store/Auth'
import { useAnalysis } from 'store/Analysis'
import { useReference } from 'store/Reference'
import { WEBINAR_TYPES, TRACKING, COLORS, LANG_JP } from 'config/constants/common'
import { ASSOCIATION } from 'config/constants/file'
import { CONTACT } from 'config/constants/panel'
import { DEFAULT_TIMEZONE } from 'config/constants/timezone'
import moment from 'moment'
import { customTypography, customButton } from 'utils/customComponentMaterialUI'
import { getMailTo, getURL, joinZoomMeeting } from 'utils/common'
import { pageView, pageEvent } from 'utils/tracker'
import { fetchApiAnalysis } from 'utils/apis'
import defaultImg from 'assets/images/default.jpg'
import './index.scss'

const labels = ['San', 'Bird']
let listFeatures = labels.map((label) => ({
  label,
  url: defaultImg,
}))
const WEBINARS = {
  TIME_START: 9,
  TIME_END: 17,
}

export default function PanelDetail(props) {
  const { t, i18n } = useTranslation(['exhibitions', 'home', 'common'])
  const { language: currentLang } = i18n
  const { panelsViews, getListPanelsViews } = useAnalysis()
  const [imgViewerSrc, setImgViewerSrc] = useState(null)
  const { listFavorite = [], getMyListFavorite } = useReference()
  const { data } = props || {}
  const { postgres = {} } = data || {}
  const { ecmsEventPanelByPanelId: panel = {} } = postgres || {}
  const {
    panelId,
    shortCode,
    discussionLink,
    resourceWebsite,
    ecmsUserByMainUserId = {},
    ecmsEventWebinarsByPanelId = {},
    ecmsEventFilesByPanelId = {},
    ecmsEventPanelExhibitorsByPanelId = {},
  } = panel || {}

  useEffect(() => {
    // Fetch analysis apis
    async function fetchApis() {
      await fetchApiAnalysis(getListPanelsViews)
    }

    fetchApis()
    pageView(trackingEventSource)
    if (listFavorite.length === 0) {
      getMyListFavorite()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { nodes: listExhibitors = [] } = ecmsEventPanelExhibitorsByPanelId || {}
  const { email: hostEmail = '' } = ecmsUserByMainUserId || {}
  const { nodes: lstPanelFiles = [] } = ecmsEventFilesByPanelId || {}
  const lstFilesPhoto = []
  const lstFilesAudio = []
  const lstFilesVideo = []
  lstPanelFiles.forEach((file) => {
    if (file.keyCode === 'FE1') {
      lstFilesPhoto[0] = { label: file.fileCode, url: file.url }
    }
    if (file.keyCode === 'FE2') {
      lstFilesPhoto[1] = { label: file.fileCode, url: file.url }
    }
    if (file.keyCode === 'AUD') {
      lstFilesAudio.push(file)
    }
    if (file.keyCode === 'VID') {
      lstFilesVideo.push(file)
    }
  })

  const videoUrl = lstFilesVideo.length > 0 ? lstFilesVideo[lstFilesVideo.length - 1].url : ''
  const videoUrlSplit = videoUrl.split('.') || ''
  const videoType = videoUrlSplit[videoUrlSplit.length - 1].toLowerCase()

  listFeatures = lstFilesPhoto.length > 0 ? lstFilesPhoto : listFeatures
  const filesDownload = lstPanelFiles.filter((file) => {
    const { fileType, keyCode } = file || {}
    return fileType === ASSOCIATION.PANEL && keyCode === 'ATT'
  })
  const canBeDownload = Array.isArray(filesDownload) && filesDownload.length > 0

  const { nodes: webinars = [] } = ecmsEventWebinarsByPanelId || {}
  const unlistedWebinars = webinars.filter((webinar) => {
    const { webinarType = '' } = webinar || {}
    return WEBINAR_TYPES.UNLISTED === webinarType
  })

  const DescriptionTypograph = customTypography('#fff')
  const ExhibitionHostTypograph = customTypography('#333')
  const DarkButton = customButton('#333', COLORS.clSecond)
  const LightButton = customButton('#fff', '#ccc')

  const { isAuth } = useAuth()
  const [openVideo, setOpenVideo] = useState(false)
  const match768 = useMediaQuery('(max-width: 768px)')
  const match567 = useMediaQuery('(max-width: 567px)')
  const match480 = useMediaQuery('(max-width: 480px)')

  const [statusLive, setLive] = useState(false)

  useEffect(() => {
    const now = getJSTMoment()
    const currentHour = now.hour()
    const currentMinute = now.minute()
    const currentSecond = now.second()
    const currentMillisecond = now.millisecond()

    const getTimeOutLiveEnable = () =>
      setTimeout(() => {
        setLive(true)
        getTimeOutLiveDisable()
      }, (24 - WEBINARS.TIME_END + WEBINARS.TIME_START) * 3600 * 1000)

    const getTimeOutLiveDisable = () =>
      setTimeout(() => {
        setLive(false)
        getTimeOutLiveEnable()
      }, (WEBINARS.TIME_END - WEBINARS.TIME_START) * 3600 * 1000)

    let timeOut
    if (currentHour >= WEBINARS.TIME_START && currentHour < WEBINARS.TIME_END) {
      setLive(true)
      timeOut = setTimeout(() => {
        setLive(false)
        getTimeOutLiveEnable()
      }, ((WEBINARS.TIME_END - currentHour) * 3600 - currentMinute * 60 - currentSecond) * 1000 - currentMillisecond)
    } else {
      setLive(false)
      const remainTime =
        currentHour < 24
          ? WEBINARS.TIME_START + 24 - currentHour
          : WEBINARS.TIME_START - currentHour
      timeOut = setTimeout(() => {
        setLive(true)
      }, (remainTime * 3600 - currentMinute * 60 - currentSecond) * 1000 - currentMillisecond)
    }

    return () => {
      clearTimeout(timeOut)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getJSTMoment = () => {
    return moment().utc().utcOffset(DEFAULT_TIMEZONE.utcOffset)
  }

  function joinZoom(index, webinar) {
    pageEvent(trackingEventSource, `zoom${index}`)
    joinZoomMeeting(webinar)
  }

  const trackingEventSource = TRACKING.SOURCE.EXHIBITION_DETAIL + panelId
  let styleFontFamily = {}
  if (currentLang === LANG_JP) {
    styleFontFamily = {
      // eslint-disable-next-line quotes
      fontFamily: "Meiryo, 'Noto Sans Regular', sans-serif",
    }
  } else {
    styleFontFamily = {
      // eslint-disable-next-line quotes
      fontFamily: "'Noto Sans Regular', Meiryo, sans-serif",
    }
  }

  return (
    <div id="panel-detail-view" className={'Layout-children'}>
      <AppHeading breadCrumbTitle={panel.panelCode} />
      <SEO title={panel.panelName} />
      <AppHeadingTitle
        shortCode={shortCode ? shortCode.toString().substr(0, 3) : ''}
        title={panel.panelName}
      />

      <Box className="media-container" component={Container}>
        <div className="media-left-container">
          <ImageSlider
            isAuth={isAuth}
            listImage={listFeatures}
            haveInteractive
            firstSliderProps={{
              className: 'media-img-first_slider',
              props: {
                arrows: false,
              },
            }}
            secondSliderProps={{
              className: 'media-img-second_slider',
              hide: match567,
            }}
            interactiveProps={{
              className: 'media-img-interactive',
              canBeDownload,
              filesDownload,
              panelId: panel.panelId,
              trackingEventSource: trackingEventSource,
            }}
            resourceButton={
              <a
                href={resourceWebsite ? getURL(resourceWebsite) : '#'}
                target="_blank"
                rel="noreferrer"
                className="IEbuttons"
              >
                <DarkButton className="btns btns-attend resourceButton">
                  <p className="btns-txt">{t('lbl_resource_url_en')}</p>
                </DarkButton>
              </a>
            }
            views={panelsViews[panelId]}
            onClickImage={(src) => setImgViewerSrc(src)}
          />

          {lstFilesAudio.length > 0 && (
            <div>
              <Wavesf
                size={match480 ? 30 : match768 ? 40 : 50}
                url={lstFilesAudio[lstFilesAudio.length - 1].url}
                trackingEventSource={trackingEventSource}
                withVolumeSlider={!match768}
              />
            </div>
          )}
        </div>

        <div className="media-right-container">
          <div
            className="video-container_thumbs"
            style={!videoUrl ? { backgroundColor: 'rgba(51,51,51,0.3)', paddingTop: '56.25%' } : {}}
          >
            {videoUrl && (
              <video
                className="video_thumbs"
                src={videoUrl}
                type={`video/${videoType}`}
                autoPlay={isIOS && isMobileOnly}
              />
            )}
            <Button
              className="video-button-play"
              onClick={() => setOpenVideo(true)}
              disabled={!videoUrl}
            >
              {videoUrl ? (
                <PlayCircleOutline color="secondary" className="video-button-play-icon" />
              ) : (
                t('common:lbl_no_video_en')
              )}
            </Button>
          </div>

          <div className="Webinar-container">
            <div className="Webinar">
              <ExhibitionHostTypograph
                className="host-label"
                variant={'h5'}
                style={styleFontFamily}
              >
                {t('lbl_QA_zoom_en')}
              </ExhibitionHostTypograph>

              <p className="host-info" style={styleFontFamily}>
                {t('lbl_QA_noti')}
              </p>

              <div className="Webinar-specific">
                <div className="Webinar-time">
                  <p className="Webinar-time-value">
                    {`${(WEBINARS.TIME_START < 10 ? '0' : '') + WEBINARS.TIME_START}:00 - ${
                      (WEBINARS.TIME_END < 10 ? '0' : '') + WEBINARS.TIME_END
                    }:00`}
                    <span className="Webinar-time-zone">{' JST'}</span>
                  </p>
                </div>

                {unlistedWebinars.length > 0 && statusLive && (
                  <div className="Webinar-status">
                    <span className={'Webinar-status_icon live'} />
                    {t('common:lbl_live_en')}
                  </div>
                )}
              </div>
            </div>

            <div className={'btns-container'}>
              {unlistedWebinars.map((webinar, index) => (
                <div key={index} className="btns-row">
                  <p className="Webinar-name">{webinar.webinarName}</p>
                  <DarkButton
                    variant="contained"
                    className={'btns btns-attend'}
                    disabled={!statusLive}
                    onClick={() => joinZoom(index + 1, webinar)}
                  >
                    <p className="btns-txt">{`ZOOM ${index + 1}`}</p>
                  </DarkButton>
                </div>
              ))}{' '}
            </div>
          </div>

          {/* MAIN-SPEAKER */}
          <Box className="host-container">
            {/* <ExhibitionHostTypograph className="host-label" variant={'h5'}>
              {t('lbl_exhibitors_en')}
            </ExhibitionHostTypograph> */}

            <div className="host-list-item">
              {listExhibitors.length > 0 ? (
                listExhibitors.map((exhibitor, index) => {
                  const { ecmsUserByUserId = {} } = exhibitor || {}
                  const { displayName = '', position = '', ecmsOrganisationByOrgId = {} } =
                    ecmsUserByUserId || {}
                  const { orgName = '' } = ecmsOrganisationByOrgId || {}

                  return (
                    <div className="host-item" key={index}>
                      <div>
                        <p className="host-name">{displayName}</p>
                        {(position || orgName) && (
                          <p className="host-info">
                            {`${position || ''}${position && orgName ? ' - ' : ''}${orgName || ''}`}
                          </p>
                        )}
                      </div>
                    </div>
                  )
                })
              ) : (
                <p style={{ marginBottom: 0 }}>{t('lbl_no_exhibitor_en')}</p>
              )}
            </div>
          </Box>
        </div>
      </Box>

      {videoUrl && (
        <ModalVideo
          isOpen={openVideo}
          onClose={() => setOpenVideo(false)}
          video={{ url: videoUrl, fileCategory: videoType }}
          trackingEventSource={trackingEventSource}
        />
      )}

      <Box className="description">
        <Container className="description-container">
          <div className="description-container-left">
            <div className="label-container">
              <DescriptionTypograph className="description-label label-txt" variant={'h5'}>
                {t('lbl_description_en')}
              </DescriptionTypograph>
            </div>

            <div className="description-detail">
              <p>{panel.panelDescription}</p>
            </div>
          </div>

          <div className="description-container-right">
            <div className="description-container-btns">
              <DescriptionTypograph className="description-label label-txt" variant={'h5'}>
                {t('lbl_contact_en')}
              </DescriptionTypograph>
              <a href={getMailTo(hostEmail || CONTACT.TO, CONTACT.CC)} className="IEbuttons">
                <LightButton className="btns btns-drop">
                  <p className="btns-txt description-btns-txt">{t('btn_drop_contact_en')}</p>
                </LightButton>
              </a>
            </div>

            <p className="description-txt-mail">{hostEmail || ''}</p>
          </div>
        </Container>
      </Box>

      <Box component={Container} className="comments">
        <ExhibitionHostTypograph className="host-label" variant={'h5'}>
          {t('lbl_comments_en')}
        </ExhibitionHostTypograph>
        <div className="comments-content">
          <a
            href={discussionLink ? getURL(discussionLink) : '#'}
            target="_blank"
            rel="noreferrer"
            className="IEbuttons comments-bbs"
            style={{ marginBottom: 24 }}
          >
            <DarkButton className="btns btns-attend">
              <p
                className="btns-txt"
                // eslint-disable-next-line quotes
                style={{ textTransform: 'none', fontFamily: "'Segoe UI', sans-serif" }}
              >
                {t('lbl_slido')}
              </p>
            </DarkButton>
          </a>

          <p style={{ ...styleFontFamily, marginBottom: 6 }}>{t('lbl_slido_descript_1')}</p>
          <p style={{ ...styleFontFamily, marginBottom: 24 }}>{t('lbl_slido_descript_2')}</p>
          <p style={{ ...styleFontFamily, marginBottom: 0 }}>{t('lbl_slido_descript_3')}</p>
          {currentLang !== LANG_JP && (
            <p style={{ ...styleFontFamily, marginBottom: 0 }}>{t('lbl_slido_descript_4')}</p>
          )}
        </div>
      </Box>
      <ImageViewer isOpen={imgViewerSrc} src={imgViewerSrc} onClose={() => setImgViewerSrc(null)} />
    </div>
  )
}

export const query = graphql`
  query($panelId: Int!) {
    postgres {
      ecmsEventPanelByPanelId(panelId: $panelId) {
        eventId
        languageCode
        panelCode
        panelDescription
        panelId
        panelName
        shortCode
        discussionLink
        resourceWebsite
        ecmsUserByMainUserId {
          email
        }
        ecmsEventFilesByPanelId {
          nodes {
            id
            eventId
            fileCategory
            fileCode
            fileDescription
            fileId
            fileSize
            fileType
            fileName
            keyCode
            url
          }
        }
        ecmsEventWebinarsByPanelId(orderBy: WEBINAR_CODE_ASC) {
          nodes {
            webinarId
            webinarName
            webinarType
            languageCode
            meetingId
            meetingPwd
            startDate
            endDate
            timezoneId
            sequenceNo
            zoomLink
          }
        }
        ecmsEventPanelExhibitorsByPanelId {
          nodes {
            ecmsUserByUserId {
              displayName
              position
              ecmsOrganisationByOrgId {
                orgName
              }
            }
          }
        }
      }
      allEcmsFileKeys {
        nodes {
          keyName
          keyCode
        }
      }
    }
  }
`
