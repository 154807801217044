import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Slider from 'react-slick'
import ButtonActions from 'components/widgets/Interactive/ButtonActions'
import { FAVORITE_TYPES, IS_HIDDEN_VIEWS } from 'config/constants/common'
import formatNumber from 'utils/common'
import panelImgPath from 'assets/images/default.jpg'
import { isMobile } from 'react-device-detect'

import './index.scss'

class AsNavFor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    const {
      t,
      views = 0,
      listImage = [],
      haveInteractive,
      firstSliderProps = {},
      secondSliderProps = {},
      interactiveProps = {},
      onClickImage = () => {},
      resourceButton,
    } = this.props
    const { canBeDownload, filesDownload, panelId, trackingEventSource } = interactiveProps
    const size = listImage.length

    return (
      <div className="slider_container">
        <Slider
          dots
          fade={false}
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          className={`first_slider ${firstSliderProps.className}`}
          arrows={false}
          swipe={isMobile}
          {...(firstSliderProps.props || {})}
        >
          {listImage.map((img, index) => {
            const { label = '', url = panelImgPath } = img
            return (
              <div
                className="first_slider-img-wrap"
                key={`slider1item${index}`}
                onClick={() => onClickImage(url)}
              >
                <img className="img-default first_slider-img" src={url} alt={label} />
              </div>
            )
          })}
        </Slider>

        <div className="ImageSlider-bottomContainer">
          <Slider
            dots={false}
            asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
            slidesToShow={size > 6 ? 6 : size}
            focusOnSelect
            arrows
            className={`second_slider ${secondSliderProps.className} ${
              secondSliderProps.hide && 'second_slider-hide'
            }`}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: size > 4 ? 4 : size,
                  arrows: false,
                },
              },
              {
                breakpoint: 690,
                settings: {
                  slidesToShow: size > 3 ? 3 : size,
                  arrows: false,
                },
              },
            ]}
            {...(secondSliderProps.props || {})}
          >
            {listImage.map((img, index) => {
              const { label = '', url = panelImgPath } = img
              return (
                <div className="second_slider-img-wrap" key={`slider2item${index}`}>
                  <img className="img-default" src={url} alt={label} />
                </div>
              )
            })}
          </Slider>

          <div className="others-container">
            {haveInteractive && (
              <div
                className={`interactive ${interactiveProps.className} WebinarItem-actions_group`}
              >
                <div>
                  {!IS_HIDDEN_VIEWS && (
                    <p className="WebinarItem-analysis">
                      <strong>{formatNumber(views)}</strong>
                      {t('common:lbl_views_en')}
                    </p>
                  )}
                </div>
                <div>
                  <ButtonActions
                    type={FAVORITE_TYPES.EXHIBITION}
                    isDownload={canBeDownload}
                    filesDownload={filesDownload}
                    panelId={panelId}
                    trackingEventSource={trackingEventSource}
                  />
                </div>
              </div>
            )}

            <div className="resourceButton-container">{resourceButton}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation('home')(AsNavFor)
